import { setAddressSource } from './actions/address.subjects';

const state = {
  currentAddress: {
    formatted_address: '',
    longitude: -1,
    latitude: -1,
    city: '',
    postalCode: '',
    place_id: ''
  }
};

export const setAddress = address => {
  state.currentAddress = address;
  setAddressSource.next();
};
export const getAddress = () => state.currentAddress;
export const getFormattedAddress = () => state.currentAddress.formatted_address;
export const getLatitude = () => state.currentAddress.latitude;
export const getLongitude = () => state.currentAddress.longitude;
export const getPlaceId = () => state.currentAddress.place_id;
