import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { COUNT_CES, FIND_CES } from '../../utils/endPoints';
import { NearbyCEsRequest, ExaminationCenter } from '../../models/examination-center.models';

@Injectable({
  providedIn: 'root'
})
export class CandidateSessionService {
  constructor(private httpClient: HttpClient) {}

  countNearByCEs(searchParams: NearbyCEsRequest): Observable<number> {
    const { latitude, longitude } = searchParams;
    const params = new HttpParams().set('latitude', latitude.toString()).set('longitude', longitude.toString());

    return this.httpClient.get<number>(COUNT_CES, { params });
  }

  findNearbyCEs(searchParams: NearbyCEsRequest): Observable<ExaminationCenter[]> {
    const { latitude, longitude, earliest } = searchParams;
    const params = new HttpParams()
      .set('latitude', latitude.toString())
      .set('longitude', longitude.toString())
      .set('earliest', earliest);

    return this.httpClient.get<ExaminationCenter[]>(FIND_CES, { params });
  }
}
