import { setRegionSource } from './actions/regions.subjects';

const initialState = {
  currentRegion: {
    id: -1,
    name: ''
  }
};

const state = { ...initialState };

export const resetCurrentRegion = () => {
  state.currentRegion = initialState.currentRegion;
  setRegionSource.next();
};
export const setCurrentRegion = region => {
  state.currentRegion = region;
  setRegionSource.next();
};
export const getCurrentRegion = () => state.currentRegion;
export const getCurrentRegionId = () => state.currentRegion.id;
