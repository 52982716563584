import { Injectable } from '@angular/core';
import { setAddress } from '../address.state';

@Injectable({
  providedIn: 'root'
})
export class AddressActionsService {
  constructor() {}

  setAddress(address) {
    setAddress(address);
  }
}
